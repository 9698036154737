import React from 'react';
import {Outlet} from 'react-router-dom';
import NavBar from '../components/Navbar';
import Footer from '../components/Footer';

const Layout = () => {
    return (
        <>
            <NavBar />
            <Outlet />
            <Footer />
        </>
    )
}

export default Layout;